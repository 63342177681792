import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PageHeader, BannerLeft } from "../utils"
import tacoTrain from "../images/bcg/taco-train-azalea.jpg"

const SpecialsPage = () => (
  <Layout>
    <Seo
      title="Specials"
      keywords={[
        `azalea bar & kitchen special offers`,
        `breakfast lunch dinner in asheville nc`,
        `restaurants near biltmore in asheville nc`,
      ]}
    />

    <PageHeader img={tacoTrain}>
      <BannerLeft
        title="Specials"
        subtitle="Taco Tuesdays / $1 Tacos & $4 Pints"
      >
        <hr />
      </BannerLeft>
    </PageHeader>
  </Layout>
)

export default SpecialsPage
